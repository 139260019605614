import React from 'react'
import { Navigate } from 'react-router-dom'
import { ApiError } from './utils/api-client/user-platform-api-client'

export class ErrorBoundary extends React.Component<{
  children: React.ReactNode
  locationPathname: string
}> {
  state = { isError: false, isAuthError: false }

  static getDerivedStateFromError(error: Error | ApiError) {
    console.log('getDerivedStateFromError', error)
    if (error instanceof ApiError) console.log('error.status', error.status)
    if (error instanceof ApiError && error.status === 499) return { isAuthError: true, isError: true }
    if (error instanceof ApiError && error.status === 302) return { isAuthError: true, isError: true }
    // 0 is the status code for hidden redirects (e.g. unauthenticated requests)
    if (error instanceof ApiError && error.status === 0) return { isAuthError: true, isError: true }
    return { isAuthError: false, isError: true }
  }

  render() {
    if (location.pathname === '/login') return this.props.children
    if (this.state.isAuthError) return <Navigate to={`/login?redirect_path=${this.props.locationPathname}`} replace />
    if (this.state.isError) return <div>An error occurred. Please refresh the page.</div>
    return this.props.children
  }
}
