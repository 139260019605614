import createClient from 'openapi-fetch'
import type { paths } from './user-platform-api.d.ts'

export const client = createClient<paths>({
  baseUrl: '',
  headers: { 'X-Requested-With': 'JavaScript' },
  redirect: 'follow',
})

client.use({
  onResponse: ({ response }) => {
    if (response.status === 499) throw new ApiError(response.status)
    // Return undefined to return unmodified response
    return undefined
  },
})

export class ApiError extends Error {
  constructor(
    public status: number,
    bodyAsText?: string | object
  ) {
    super(`API request failed with status ${status}`)
    this.name = 'ApiError'
    this.status = status

    if (bodyAsText === undefined || bodyAsText === null) {
      return
    }

    if (typeof bodyAsText === 'object') {
      this.bodyAsText = JSON.stringify(bodyAsText, undefined, 2)
      return
    }

    if (typeof bodyAsText === 'string') {
      this.bodyAsText = bodyAsText
      return
    }

    console.error(
      `ApiError constructor expected bodyAsText to be a string or object but was a ${typeof bodyAsText}: ${bodyAsText}`
    )
  }

  public bodyAsText?: string
}
